<template>
	
	<div>
		
		<div class="border  ">
			
			<swiper ref="teacher-Swiper" :options="teacherswiperOptions">
				<swiper-slide v-for="v in 6">
					<div class="d-flex justify-content-center">{{v}}</div>
				</swiper-slide>
			</swiper>
			<div class="swiper-button-prev" slot="button-prev"></div>
			<div class="swiper-button-next" slot="button-next"></div>
			
		</div>
	</div>

 
</template>
<script>
  import {
  	Swiper,
  	SwiperSlide
  } from "vue-awesome-swiper";
  import "swiper/css/swiper.css";
  
  export default {
    components: {
      Swiper,
      SwiperSlide,
    },
	data(){
		return{
			teacherswiperOptions: {
				slidesPerView: 3,
			
				slideToClickedSlide: true,
				// centeredSlides: true,
				spaceBetween: 53, //设置距离
				centeredSlidesBounds: true, //当设置了Active Slide居中后，使得第一个和最后一个Slide 始终贴合边缘。
			
				//spaceBetween : '10%',按container的百分比
			
				// // 箭头配置
				// navigation: {
				// 	nextEl: ".swiper-button-next",
				// 	prevEl: ".swiper-button-prev",
				// },
			},
			
		}
	},
    methods: {
      onSwiper(swiper) {
        console.log(swiper);
      },
      onSlideChange() {
        console.log('slide change');
      },
    },
  };
</script>